:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 2rem; /* must NOT be a percentage value */
    --vertical-margin: 2rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1200px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: "Hind", sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Roboto.css";
@import "/fontmodules/Adamina.css";
@import "/fontmodules/Lato.css";
@import "/fontmodules/Oswald.css";
@import "/fontmodules/Muli.css";

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.1.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/

.storbild, .minibild {background-size:cover; background-position:center center; }
.storbild {min-height:55vh;}

.minibild, .storbild, .minibild_wrapper {margin:5px !important; }
.minibild:first-child {margin-top:0 !important;}
.minibild:last-child {margin-bottom:0 !important;}

.minibild:hover {
    cursor: pointer;
}

/*logo gallery*/
.logotype-gallery {padding: 2em 0;}
.logotype-image {min-height: 70px;} 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} 
.standing  {width:40%; } 
.squarish  {width:40%; } 
.landscape  {width:55%; }
.longlandscape {width:70%; }
.extralonglandscape {width:100%; }
/* ------------------WRAPPERS---------------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}

/* ------------- Generellt ------------- */
html {
    height: 100%;
}
body {
    font-family: "Muli", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    line-height: 1.5;
}

header, nav {
    background: #fff;
}

img {
    max-width: 100%;
    height: auto;
}
.hcontainer {
    width: 100%;  
    margin: 0 auto;
}
/* ------------- FONTS ------------- */
h1, h2, h3, h4 {
    font-family: "Oswald";
    font-weight: 300;
}
h1 {
    margin-bottom: 0px;
    text-align: center;
}
.projekt h1 {

    text-align: left;
}

p, span {
    font-family: "Muli";
    font-weight: 200;
}

/* ------------NAV + HEADER--------------- */

header {
    border-bottom: 2px solid white;
}
nav {
    z-index: 10; 
    margin-top: 4vh !important;
    margin-bottom: 2vh !important;
}
header ul.menu {
    font-family: "Lato";
    font-size: 1.2em;
    letter-spacing: 2px;
}

.menu li, .menu ul, .menu.menu {
    padding: 0 3px;
}

header > div {
    min-height: 100px;
}


.logo a, .logo img {
    display: block;
    margin: 0px;
    max-height: 15vh;
    height: 15vh;
   
}
.logo {
    margin: 0px;
    padding-top: 1vh;
}
    .menu.menu li a:hover, .menu.menu li.active a {
    background: #d5d5d5;
            padding: 10px;

}

.menu.menu li a {
                padding: 10px;

}

/* ---------------SLIDER----------- */

.slick {
    position: relative;
    margin-bottom: 0;
}
.slick-slide {
    display: flex !important;
    
}
.slick, .slick-slide, .slick-substitute > div {
    min-height: 50vh;
}
.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-title {
    font-size: 2rem;
    color: inherit;
    background-color: rgba(255, 255, 255, 0.49);
    padding: 0px 10px;
    text-align: center;
}

/* ----------------STARTTEXT------------  */
.starttext p{
    margin-bottom: 0px;
    margin-top: 0px;
}
.starttext h1 {
    margin-bottom: 0px;
    margin-top: 0px;
}

/* ------------------STARTBOXARNA----------- */
.startboxar {
    margin-top: 0px;
}
.startbox {
    height: 35vh;
    color: white;
    background-size: cover;
    
}
a.boxwrapper {
    background-color: rgba(109, 106, 106, 0.4);
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 2em;  
    text-align: center;
}
/* -----------------boxen HOVER ----------- */
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.6);  
    transition:background 0.5s ease-in 0s;
}
/* -----------------boxen HOVER rubrik ----------- */
.startbox:hover a.boxwrapper h3 {
    opacity: 1;
    color: white;
    transition:opacity 0.5s ease-in 0s;
}

/* ------------------Projektboxarna----------- */
.prostartbox {
    height: 27vh;
    color: white;
    background-size: cover;
}

a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;    
}

.prostartbox a.boxwrapper h3 {
    transition:font-size 0.5s ease-in 0s;
    font-size: 1.8rem;
    color: white;
    
}

/* -----------------Projektboxarna HOVER ----------- */
.prostartbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.7);  
    transition:background 0.5s ease-in 0s;    
}
/* -----------------Projektboxarna HOVER rubrik ----------- */
.prostartbox:hover a.boxwrapper h3 {
    transition:font-size 0.5s ease-in 0s;
    font-size: 2rem;
}

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}
main {
    flex: 1 0 auto;
    min-height: 1%;   
}

header, footer {
    flex: none;
}
footer {
    background-color: lightgrey;
}
/* -----------------FOOTER---------------- */
.sidfot h4 {
    font-size: 1.2em;
    margin-top: 0px;    
}

.sidfot span {
    display: block;
}
.intenditfooter {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 0.8em;
}
#footer {
    background-color: rgba(142, 142, 142, 0.25);
}

.sidfot i {
    min-width: 30px;
}
.sidfot p, .sidfot span, .sidfot h4 {
    font-family: "Lato";
    font-weight: 400;
}
h4 {
    margin-bottom: 0px;
}

/* -----------------KONTAKT---------------- */
.contactcontent p, .contactcontent span, .contactcontent h4 {
    font-family: "Lato";
    font-weight: 400;
}

.contactcontent h4 {
    font-weight: 600;
    margin-bottom: 0px;
}
.contactcontent p {
    margin-top: 0px;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
    .slider-content h2 {
        font-size: 2em;
        line-height: 1;
    }
    .container {
        width: 97%;
    }
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .storbild {
        min-height: 55vw;
    }
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
}
